:root {
  --primary: #0e3f6e;
  --secondary: #FCBF01;
}

html {
  scroll-behavior: smooth;
}

html, body {
  font-family: "Montserrat", sans-serif;
}

.text-primary {
  color: var(--primary)!important;
}

.text-secondary {
  color: var(--secondary)!important;
}

.bg-primary {
  background-color: var(--primary)!important;;
}

.bg-secondary {
  background-color: var(--secondary)!important;;
}

.btn {
  padding-right: 22px;
  padding-left: 22px;
  border-radius: 18px;
  text-transform: uppercase;
}

.btn:hover {
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
  box-shadow: 0 0 0 1em hsl(0deg 0% 100% / 0%);
}

.btn-secondary, .btn-secondary:focus, .btn-secondary:hover {
  background-color: var(--secondary)!important;
  border-color: var(--secondary)!important;;
  color: var(--dark)!important;;
  font-weight: bold!important;;
}

#header {
  position: absolute;
  z-index: 2;
  width: 100%;
  text-transform: uppercase;
}

.nav-fixed {
  background-color: var(--primary);
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.nav-item {
  line-height: 60px;
  padding: 0 17px;
}

.nav-link {
  font-size: 0.7em;
}

.nav-link:hover {
  -webkit-box-shadow: inset 0px -4px 0px 0px var(--secondary);
  -moz-box-shadow: inset 0px -4px 0px 0px var(--secondary);
  box-shadow: inset 0px -4px 0px 0px var(--secondary);
}

.navbar-close {
  display: none;
  float: right;
  font-size: 2.5rem;
  color: #ffffff;
  padding: 0!important;
}

.banner {
  position: relative;
  text-transform: uppercase;
  background-image: url("../img/banner.png");
  background-position: 70%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 668px;
}

.banner:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.banner-slide-content {
  position: absolute;
  left: 5%;
  text-align: left;
  color: #ffffff;
  top: 240px;
  max-width: 400px;
}

.banner-social {
  position: absolute;
  top: 525px;
  width: 100%;
  color: #ffffff;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.quem-sou {
  padding: 56px 42px;
  background-color: #ffffff;
  margin: -62px auto -102px;
  position: relative;
  max-width: 930px;
}

.doacao {
  padding: 132px 0 72px;
  /*background-position: 95% bottom;*/
  /*background-size: 400px;*/
  /*background-repeat: no-repeat;*/
  /*background-image: url("../img/banner-2.png");*/
}

.doacao .container {
  max-width: 930px;
}

.social-item {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.efeito {
  padding: 72px 0;
}

.propostas .card {
  margin: 52px auto;
  padding-bottom: 22px;
  max-width: 930px;
}

.propostas-header {
  position: relative;
  text-transform: uppercase;
  background-image: url("../img/banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.propostas-header:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(252,192,1,0.95);
  z-index: 0;
}

.propostas-header .container {
  position: relative;
  z-index: 2;
  max-width: 930px;
  padding-top: 72px;
  padding-bottom: 72px;
}

.propostas-icon {
  text-align: center;
  margin: 42px 0 42px;
  display: inline-block;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--primary);
  color: #ffffff;
  width: 114px;
}

.footer .container {
  padding-top: 92px
}

.footer {
  background-color: var(--primary);
  color: #f8f9fa;
  position: relative;
  background-image: url("../img/banner.png");
  background-position: top;
  background-size: cover;
}

.footer:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(14,63,110,0.96);
}

.sub-footer {
  margin-top: 82px;
  padding: 12px;
  position: relative;
}

.footer .form-control {
  color: #ffffff;
  background-color: transparent;
  outline: 0;
  border-width: 0 0 1px;
  border-radius: 0;
  border-color: #306598;
  padding: 0 6px;
  resize: none;
  font-size: 0.9rem;
}

.footer .form-control::placeholder {
  color: #f8f9fa;
  opacity: 0.8; /* Firefox */
}

@media (min-width: 768px) {
  .sub-footer {
    border-top: 1px solid #306598;
  }

  .banner {
    background-position: initial;
  }

  .quem-sou {
    margin: -62px auto -62px;
  }
}

@media (max-width: 992px){
  .navbar-close {
    display: block;
  }

  .navbar-nav {
    padding-top: 65px;
    display: block;
  }

  .nav-item {
    line-height: 45px;
    padding: 0;
  }

  .nav-link {
    font-size: 0.8em;
  }

  .nav-btn {
    text-align: left;
    padding: 20px;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 90%;
    width: 300px;
    background-color: var(--primary);
    padding: 0 20px 0 22px ;
    box-shadow: 0 0 0 800px rgba(0,0,0,.5),1px 0 7px 2px rgba(0,0,0,.5);
  }

  .doacao {
    background: none;
    padding-top: 162px;
  }
}

@media (max-width: 768px) {
  .social-item {
    height: 182px;
  }
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 var(--secondary); }
}
